
import { combineLatest as observableCombineLatest, from } from "rxjs";
import { Component, Inject, forwardRef, Injector } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { ErrorSeverityLevel, UserInfoService } from "@fxp/fxpservices";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";

import { CapAmountService } from "../../../../common/services/cap-amount.service";
import { ClinSlinModalComponent } from "../estimate-at-complete/estimate-at-complete-modals//clin-slin/clin-slin-modal.component";
import { CacheService } from "../../../../common/services/cache.service";
import { ConcurService } from "../../../../common/services/concur.service";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.selector";
import { getEntireProjectDetails } from "../../../../store/project-details/project-details.selector";
import { getDemandsState } from "../../../../store/demands/demands.selector";
import { groupBy, mergeMap, toArray } from "rxjs/operators";
import { IClinSlinDisplayObject, IClinSlinRoleDetail, IClinSlinDemandDetails, IClinSlinProjectDetails } from "../../../../common/services/contracts/clinSlinModal.contracts";
import { IConcurPendingReportsResponse } from "../../../../common/services/contracts/concur.contracts";
import { IDemandDetails } from "../../../../common/services/contracts/project.service.v2.contracts";
import { IEngagementDetailsApiV2, IProjectDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { IExpenseDetail, IUnapprovedExpense, IEntry } from "../../../../common/services/contracts/unapproved-expense.contracts";
import { IFinancialDetailsV2State } from "../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IProjectDetails, IClinSlinApiResponse, IFundingDetail } from "../../../../common/services/contracts/project.service.contracts";
import { IProjectDetailsState } from "../../../../store/project-details/project-details.reducer";
import { IRiskReserveDetail, ICapAmountDetails, IEntityFinancials, IEntityFinancialSummary } from "../../financial.model";
import { IState, ILoadableState } from "../../../../store/reducers";
import { IUnapprovedLaborList, ILaborDetails, ILaborWeeklyData } from "../../../../common/services/contracts/unapproved-labor.contracts";
import { IDemandsState } from "../../../../store/demands/demands.reducer";
import { PlanForecastComponent } from "../../../plan-forecast/planForecast.component";
import { ProjectService } from "../../../../common/services/project.service";
import { RiskReserveModalComponent } from "../estimate-at-complete/estimate-at-complete-modals/risk-reserve/risk-reserve.component";
import { RouteName, Components, FinancialType, CacheKeys, CacheStorageOptions, SourceConstants, LogEventConstants, ErrorText } from "../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { UnapprovedExpenseComponent } from "./unapproved-modals/unapproved-expense-modal/unapproved-expense-modal.component";
import { UnapprovedLaborComponent } from "./unapproved-modals/unapproved-labor-modal/unapproved-labor-modal.component";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ViewCapAmountsModalComponent } from "../estimate-at-complete/estimate-at-complete-modals/view-cap-amounts/view-cap-amounts.component";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import moment from "moment";
import { LaborManagementService } from "../../../../common/services/labor-management.service";
import { ILaborDetail, IBulkLaborEntries } from "../../../../common/services/contracts/labor-management.contract";
import { getEntireResourceRequestsProjectContextStateObject } from "../../../../store/resource-requests-project-context/resource-requests-project-context.selector";
import { getEntireResourceRequestsStateObject } from "../../../../store/resource-requests/resource-requests.selector";
import { IResourceRequestsDetailsState } from "../../../../store/resource-requests/resource-requests.reducer";
import { IResourceRequestsDetailsProjectState } from "../../../../store/resource-requests-project-context/resource-requests-project-context.reducer";
import { IResourceRequestResponse, IAssignmentDetails } from "../../../../common/services/contracts/staffing.service.contract";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../../common/error.constants";
import { ChangeRequestService } from "../../../../common/services/change-request.service";
import { IApprovedFinancialsResponseV2, IProjectApprovedFinancial } from "../../../../common/services/contracts/changerequest.contract";
import { EcifIOConsumedModalComponent } from "../estimate-at-complete/estimate-at-complete-modals/ecif/ecif-io-consumed-modal.component";
import { IEcifIoConsumption, IIoDetailApi } from "../../../../common/services/contracts/ecif-io-consumed-modal.contracts";


const WBS_STATUSCODE_UNAPPROVED_COST: string = "CRTD";
const pendingApprovalStatus = [
    "Committed",
    "Assigned",
    "Complete",
    "Closed"
];

@Component({
    selector: "dm-key-indicators",
    templateUrl: "./key-indicators.html",
    styleUrls: ["./key-indicators.scss"]
})
export class KeyIndicatorsComponent extends DmComponentAbstract {
    public capAmountDetails: ICapAmountDetails;
    public clinSlinConsumedPercentage: number = 0;
    public currency: string;
    public eacCostVsContractBaseLine: number = 0;
    public eacCostVsCurrentFinancialPlan: number = 0;
    public eacCostVsDeliveryBaseLine: number = 0;
    public eacDetails: IEntityFinancialSummary;
    public isCapAmtExceeds: boolean = false;
    public isClinSlinFeatureEnabled: boolean;
    public isClinSlinLoading: boolean;
    public isExpenseDetailsLoading: boolean;
    public isLaborDetailsLoading: boolean;
    public isProjectContext: boolean;
    public isUnapprovedExpenseEnabled: boolean = false;
    public isUnapprovedLaborEnabled: boolean = false;
    public loadingText: string = "Loading Key Indicator Details";
    public nonClinSlinDemandModel: IClinSlinProjectDetails[] = [];
    public riskReserveConsumedPercentage: number = 0;
    public riskReserveDetails: IRiskReserveDetail[];
    public approvedFinancialData: IProjectApprovedFinancial[];
    public showClinSlin: boolean;
    public tmCapConsumedPercentage: number = 0;
    public totalUnApprovedLaborHours: number = 0;
    public unapprovedExpense: number = 0; // todo get this from API
    public projectLaborResponses: ILaborDetail[] = [];
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.KeyIndicators;
    public showECIFConsumption: boolean;
    public isECIFLoading: boolean;
    public ecifConsumedPercentage: number;
    public ecifConsumption: IEcifIoConsumption[];

    private capAmtExceedPercentage: number = 0;
    private clinSlinApiResponse: IClinSlinApiResponse;
    private clinSlinDemands: string[];
    private clinSlinDisplayObject: IClinSlinDisplayObject[];
    private consumedAmount: number;
    private demandsDetails: IDemandDetails[];
    private engagementDetails: IEngagementDetailsApiV2;
    private engagementFinancialDetails: IEntityFinancials;
    private engagementId: string;
    private expenseDetailList: IExpenseDetail[] = [];
    private expenseEntryListGroupbyProjectID /* : IExpenseDetail[][]*/ = []; // to do add interface
    private fundedAmount: number;
    private laborExpenseListAPI: any; // to do add interface
    private projectData: IProjectDetailsV2;
    private projectFinancialDetails: IEntityFinancials;
    private projectId: string;
    private resourceRequestDetails: IResourceRequestResponse;
    private unapprovedExpenseEntries: IUnapprovedExpense[] = [];
    private unapprovedLaborList: IUnapprovedLaborList[] = [];
    private unApprovedLaborResources: string[] = [];
    private usPubSecCode: string;
    private isEcifIoConsumptionFeatureEnabled: boolean;

    public constructor(
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(ConcurService) private concurService: ConcurService,
        @Inject(CapAmountService) private capAmountService: CapAmountService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions,
        @Inject(LaborManagementService) private laborManagementService: LaborManagementService,
        @Inject(Store) private store: Store<IState>,
        @Inject(Injector) private injector: Injector,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(ChangeRequestService) private changeRequestService: ChangeRequestService
    ) {
        super(dmLogger, Components.KeyIndicators);
    }

    public ngOnInit(): void {
        this.ecifConsumption = [];
        this.showECIFConsumption = false;
        this.isClinSlinFeatureEnabled = this.configurationService.isFeatureEnabled("ShowClinSlinFunding");
        this.isEcifIoConsumptionFeatureEnabled = this.configurationService.isFeatureEnabled("ecifIoExtension");
        this.projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.cacheService.get(CacheKeys.FinancialRoles.KeyName, () => this.projectServiceFunctions.getFinancialRoles(), CacheKeys.FinancialRoles.Duration, CacheStorageOptions.LocalStorage);
        if (this.projectId) {
            this.isProjectContext = true;
        } else {
            this.isProjectContext = false;
        }
        this.tileContent = {
            title: "Key Indicators",
            link: { name: "Learn more", url: "https://aka.ms/pjm-job-aid/financials", tooltipText: "Learn more about Financials and Actuals", icon: "icon-education" }
        };
        const engagementDetails$ = this.isProjectContext ? this.store.select(getEntireProjectDetails(this.projectId))
            : this.store.select(getEntireEngagementDetails(this.engagementId));
        const financialDetails$ = this.isProjectContext ? this.store.select(getEntireFinancialDetailsV2(this.projectId))
            : this.store.select(getEntireFinancialDetailsV2(this.engagementId));
        const demands$ = this.isProjectContext ? this.store.select(getDemandsState(this.projectId))
            : this.store.select(getDemandsState(this.engagementId));
        const resourceRequests$ = this.isProjectContext ? this.store.select(getEntireResourceRequestsProjectContextStateObject(this.projectId))
            : this.store.select(getEntireResourceRequestsStateObject(this.engagementId));
        observableCombineLatest(
            financialDetails$,
            engagementDetails$,
            demands$,
            resourceRequests$,
            (
                financialDetails: IFinancialDetailsV2State,
                engagementDetails: IEngagementDetailsState | IProjectDetailsState,
                demands: IDemandsState,
                resourceRequests: IResourceRequestsDetailsState | IResourceRequestsDetailsProjectState
            ) => ({
                financialDetails,
                engagementDetails,
                demands,
                resourceRequests,
            })
        ).pipe(untilDestroyed(this))
            .subscribe(({
                financialDetails,
                engagementDetails,
                demands,
                resourceRequests
            }) => {
                this.checkLoadingStatus(engagementDetails, financialDetails);
                if (financialDetails.loaded && engagementDetails.loaded) {
                    let entityFinancials: IEntityFinancials;
                    let callClinSlin = false;
                    let isProjectTypeFF = false;
                    let hasUnitBasedDemands = false;
                    if (this.isProjectContext) {
                        this.engagementDetails = (engagementDetails as IProjectDetailsState).projectDetails.engagementFullDetails;
                        this.projectFinancialDetails = (financialDetails as IFinancialDetailsV2State).financialDetails;
                        this.projectData = (engagementDetails as IProjectDetailsState).projectDetails.projectFullDetails;
                        entityFinancials = this.projectFinancialDetails;
                        if (this.projectData && this.projectData.contractType === "FF") {
                            isProjectTypeFF = true;
                        }
                        hasUnitBasedDemands = this.projectData.hasUnitBasedDemands;
                    } else {
                        this.engagementFinancialDetails = (financialDetails as IFinancialDetailsV2State).financialDetails;
                        entityFinancials = this.engagementFinancialDetails;
                        this.engagementDetails = (engagementDetails as IEngagementDetailsState).engagementDetails;
                        if (this.engagementDetails && this.engagementDetails.projects && this.engagementDetails.projects.length) {
                            isProjectTypeFF = this.sharedFunctionsService.getContractType(this.engagementDetails.projects) === "FF" ? true : false;
                        }
                    }
                    this.usPubSecCode = this.engagementDetails.publicSectorCode;
                    this.currency = this.engagementDetails.currency;
                    callClinSlin = this.sharedFunctionsService.shouldCallClinSlinApi(this.usPubSecCode) && !isProjectTypeFF && !hasUnitBasedDemands && this.engagementDetails.statusCode !== "CRTD";
                    if (entityFinancials) {

                        if (isProjectTypeFF || (!this.isProjectContext && (this.sharedFunctionsService.getContractType(this.engagementDetails.projects) === "Mixed"))) {
                            this.changeRequestService.getApprovedFinancialsV2(this.engagementId).then((engagementReserveV2: IApprovedFinancialsResponseV2) => {
                                if (engagementReserveV2.projects && engagementReserveV2.projects.length) {
                                    this.approvedFinancialData = this.changeRequestService.getProjectsApprovedFinancialsV2ByVersion(engagementReserveV2.projects, FinancialType.CurrentFinancialPlan);

                                    if (this.projectId) {
                                        this.approvedFinancialData = this.approvedFinancialData.filter((cfpRrDetails: IProjectApprovedFinancial) => cfpRrDetails.wbsId === this.projectId);
                                    }
                                }
                            }).catch((error) => {
                                if (error.status === 404) {
                                    Promise.resolve([]);
                                }
                                this.errorText = ErrorText.riskReserveAmountErrorText;
                                this.logError(SourceConstants.Method.NgOnInit, error, this.errorText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                            }).then(() => {
                                this.parseAndDisplayData(entityFinancials, engagementDetails, demands, resourceRequests, callClinSlin);
                            });
                        } else {
                            this.parseAndDisplayData(entityFinancials, engagementDetails, demands, resourceRequests, callClinSlin);
                        }
                    }
                    if (this.isEcifIoConsumptionFeatureEnabled) {
                        this.getEcifIoConsumption();
                    }
                }
                if (financialDetails.error && engagementDetails.error && demands.error && resourceRequests.error) {
                    this.isServerError = true;
                }
            });
    }

    /**
     * Open Risk reserve modal popup
     */
    public openRiskReserveModal(): void {
        const modalRef: NgbModalRef = this.modalService.open(RiskReserveModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal risk-reserve in active",
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.riskReserveDetails = this.riskReserveDetails;
        modalRef.componentInstance.approvedFinancialData = this.approvedFinancialData;
        modalRef.componentInstance.currency = this.currency;
        modalRef.componentInstance.engagementId = this.engagementId;

        if (this.isProjectContext) {
            modalRef.componentInstance.projectId = this.projectId;
        }
    }

    /**
     * Open view cap amounts modal popup
     */
    public openViewCapAmountsModal(): void {
        const modalRef: NgbModalRef = this.modalService.open(ViewCapAmountsModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal risk-reserve view-cap-amounts in active",
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.capAmountDetails = this.capAmountDetails;
        modalRef.componentInstance.isAmountExceeds = this.isCapAmtExceeds;
        modalRef.componentInstance.configuredAmtPercentage = this.capAmtExceedPercentage;
        modalRef.componentInstance.currency = this.currency;

    }

    /**
     * Open CLin/SLin modal popup
     */
    public openClinSlinModal(): void {
        const modalRef: NgbModalRef = this.modalService.open(ClinSlinModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal clin-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.clinSlinFundingInformation = this.clinSlinDisplayObject;
        modalRef.componentInstance.currency = this.currency;
        modalRef.componentInstance.nonClinSlinDemandModel = this.nonClinSlinDemandModel;
    }

    public openECIFConsumedModal(): void {
        const modalRef: NgbModalRef = this.modalService.open(EcifIOConsumedModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal-v2 clin-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.currency = this.currency;
        modalRef.componentInstance.ecifIoConsumptionDetails = this.ecifConsumption;
        modalRef.componentInstance.isProjectContext = this.isProjectContext;
        modalRef.componentInstance.wbsId = this.isProjectContext ? this.projectData.id : this.engagementDetails.id;
        modalRef.componentInstance.wbsName = this.isProjectContext ? this.projectData.name : this.engagementDetails.name;
    }

    /**
     * Open Unapproved labor modal popup
     *
     * @memberof KeyIndicatorsComponent
     */
    public openUnapprovedLaborModal(): void {
        /// Filter the unique values        
        this.unApprovedLaborResources = this.unApprovedLaborResources.filter((item, pos, ar) => ar.indexOf(item) === pos);
        const modalRef: NgbModalRef = this.modalService.open(UnapprovedLaborComponent, {
            backdrop: "static",
            centered: true,
            keyboard: true,
            windowClass: "in active dm-modal unapproved-labor",
            injector: this.injector
        });
        modalRef.componentInstance.unapprovedLaborList = this.unapprovedLaborList;
        modalRef.componentInstance.projectId = this.projectId;
        modalRef.componentInstance.engagementDetails = this.engagementDetails;
        modalRef.componentInstance.resources = this.unApprovedLaborResources;
    }

    /**
     * Open Unapproved expense modal popup
     *
     * @memberof KeyIndicatorsComponent
     */
    public openUnapprovedExpenseModal(): void {
        const modalRef: NgbModalRef = this.modalService.open(UnapprovedExpenseComponent, {
            backdrop: "static",
            centered: true,
            keyboard: true,
            windowClass: "in active dm-modal unapproved-expense",
            injector: this.injector
        });
        modalRef.componentInstance.unapprovedExpenseEntries = this.unapprovedExpenseEntries;
        modalRef.componentInstance.projectId = this.projectId;
    }

    /**
     * Change state to plan&forecast and land on Contract Baseline Plan
     */
    public openContractBaseline(): void {
        PlanForecastComponent.financialPlanToOpen = "ContractBaseline";
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.OpenContractBaseline, LogEventConstants.ContractBaseLineClick);
        this.stateService.go(this.isProjectContext ? RouteName.ProjectPlanForecast : RouteName.EngagementPlanForecast);
    }

    /**
     * Change state to plan&forecast and land on Delivery Baseline Plan
     */
    public openDeliveryBaseline(): void {
        PlanForecastComponent.financialPlanToOpen = "DeliveryBaseline";
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.OpenDeliveryBaseline, LogEventConstants.DeliveryBaseLineClick);
        this.stateService.go(this.isProjectContext ? RouteName.ProjectPlanForecast : RouteName.EngagementPlanForecast);
    }

    /**
     * Change state to plan&forecast and land on the Current Financial Plan
     */
    public openCurrentFinancialPlan(): void {
        PlanForecastComponent.financialPlanToOpen = "CurrentFinancialPlan";
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.OpenCurrentFinancialPlan, LogEventConstants.CurrentFinancialPlanClick);
        this.stateService.go(this.isProjectContext ? RouteName.ProjectPlanForecast : RouteName.EngagementPlanForecast);
    }

    /**
     * Logs an event when user clicks on learn more link
     */
    public logLearnMoreClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.LogLearnMoreClick, LogEventConstants.LearnMoreFinancialsClick);
    }

    /**
     * Parse and display data gathered from promises. Consolidated into one method to clean up
     * code and prevent duplication.
     *
     * @private
     * @param {IEntityFinancials} entityFinancials
     * @param {(IEngagementDetailsState | IProjectDetailsState)} engagementDetails
     * @param {IDemandsState} demands
     * @param {(IResourceRequestsDetailsState | IResourceRequestsDetailsProjectState)} resourceRequests
     * @param {boolean} callClinSlin
     * @memberof KeyIndicatorsComponent
     */
    private parseAndDisplayData(entityFinancials: IEntityFinancials, engagementDetails: IEngagementDetailsState | IProjectDetailsState, demands: IDemandsState,
        resourceRequests: IResourceRequestsDetailsState | IResourceRequestsDetailsProjectState, callClinSlin: boolean): void {
        this.riskReserveDetails = entityFinancials.riskReserveData;
        this.eacDetails = this.financialService.getFinancialDetailsFromParentForV2Object(entityFinancials, FinancialType.EAC);
        const etcDetails = this.financialService.getFinancialDetailsFromParentForV2Object(entityFinancials, FinancialType.ETC);
        if (this.eacDetails) {
            const contractBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(entityFinancials, FinancialType.ContractBaseline);
            const deliveryBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(entityFinancials, FinancialType.DeliveryBaseline);
            const cfpDetails = this.financialService.getFinancialDetailsFromParentForV2Object(entityFinancials, FinancialType.CurrentFinancialPlan);
            if (this.financialService.IsEACETCFinancialsExists(this.eacDetails, etcDetails)) {
                this.eacCostVsContractBaseLine = this.getCostPercentage(this.eacDetails.cost, contractBaseLineDetails.cost);
                this.eacCostVsDeliveryBaseLine = this.getCostPercentage(this.eacDetails.cost, deliveryBaseLineDetails.cost);
                this.eacCostVsCurrentFinancialPlan = this.getCostPercentage(this.eacDetails.cost, cfpDetails.cost);
            } else {
                this.eacCostVsContractBaseLine = 0;
                this.eacCostVsDeliveryBaseLine = 0;
                this.eacCostVsCurrentFinancialPlan = 0;
            }

        }
        if (resourceRequests.loaded) {
            this.resourceRequestDetails = resourceRequests.grmSearchApiResponse;
            this.isUnapprovedSectionEnabled();
            this.getUnapprovedLabor();
        }
        if (demands.loaded) {
            this.demandsDetails = demands.wbsDemands.details;
            this.populateData(callClinSlin);
        }
    }

    /**
     * Calculate unapproved total labor data. Find week number and add to LaborEntryList by laborDate to get Startdate and Enddate of perticular week
     */
    private getUnapprovedLabor(): void {
        this.unapprovedLaborList = [];
        const unapprovedLaborByProject = {};
        this.totalUnApprovedLaborHours = 0;
        this.isLaborDetailsLoading = true;

        if (this.isProjectContext) {
            this.getProjectsPendingLabor(this.projectData).then(() => {
                this.prepareUnapprovedLaborViewList(this.projectData, this.projectLaborResponses);

                this.unapprovedLaborList = this.unapprovedLaborList.filter((laborList) => laborList && laborList.laborWeeklyData && laborList.laborWeeklyData.length);
                this.totalUnApprovedLaborHours = this.unapprovedLaborList.reduce((hours, details) => hours + details.totalHours, 0);
                this.isLaborDetailsLoading = false;
            }).catch((error) => {
                this.isLaborDetailsLoading = false;
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.GetUnapprovedLabor, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
        } else {

            // Get unapproved labor for each project and wait for all promises to resolve
            Promise.all(this.engagementDetails.projects.map((project: IProjectDetails | IProjectDetailsV2) => {
                const assignmentIds: IAssignmentDetails[] = this.getAssignmentsFromGRMResponse(this.resourceRequestDetails, (project as IProjectDetailsV2).id);
                const projectResponses: ILaborDetail[] = [];

                return Promise.all(assignmentIds.map((assignment: IAssignmentDetails) => {
                    return this.laborManagementService.getBulkLaborEntries(assignment.resourceId, assignment.resourceAlias).catch((err) => {
                        // If 404 error is caught, resolve promise as it just means there is no unapproved labor for given assignment ID
                        if (err.status === 404) {
                            this.logError(SourceConstants.Method.GetProjectsPendingLabor, err, DmError.KeyIndicator.NoUnapprovedlabor);
                            Promise.resolve();
                        } else {
                            const errorMessage = this.sharedFunctionsService.getErrorMessage(err, "");
                            this.logError(SourceConstants.Method.GetProjectsPendingLabor, err, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        }
                    });
                })).then((result: IBulkLaborEntries[]) => {

                    result.forEach((laborDetails: IBulkLaborEntries) => {
                        if (laborDetails && laborDetails.response && laborDetails.response.laborResponse) {
                            const filteredApiResponse: ILaborDetail[] = laborDetails.response.laborResponse.filter((labor: ILaborDetail) => labor.laborStatus.toLowerCase() === "submitted");
                            if (!filteredApiResponse.length) {
                                return undefined;
                            }
                            filteredApiResponse.forEach((laborEntry: ILaborDetail) => {
                                laborEntry.isAdded = false;
                            });
                            projectResponses.push(...filteredApiResponse);
                        }
                    });

                    unapprovedLaborByProject[(project as IProjectDetailsV2).id] = projectResponses;
                }).catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    // If 404 error is caught, resolve promise as it just means there is no unapproved labor for given assignment ID
                    if (error.status === 404) {
                        this.logError(SourceConstants.Method.GetUnapprovedLabor, error, DmError.KeyIndicator.NoUnapprovedlabor, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        Promise.resolve();
                    }
                    this.logError(SourceConstants.Method.GetUnapprovedLabor, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                });
            })).then(() => {
                const projectsWithUnapprovedLabor: IProjectDetailsV2[] | IProjectDetails[] = this.engagementDetails.projects.filter((project: IProjectDetailsV2 | IProjectDetails) => unapprovedLaborByProject[(project as IProjectDetailsV2).id] && unapprovedLaborByProject[(project as IProjectDetailsV2).id].length);
                for (const project of projectsWithUnapprovedLabor) {
                    if (unapprovedLaborByProject[project.id]) {
                        this.prepareUnapprovedLaborViewList(project, unapprovedLaborByProject[project.id]);
                    }
                }

                this.unapprovedLaborList = this.unapprovedLaborList.filter((laborList) => laborList && laborList.laborWeeklyData);
                this.totalUnApprovedLaborHours = this.unapprovedLaborList.reduce((hours, details) => hours + details.totalHours, 0);
                this.isLaborDetailsLoading = false;
            }).catch((error) => {
                this.isLaborDetailsLoading = false;
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.GetUnapprovedLabor, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
        }
    }

    /**
     * Gets ECIF IO Consumption details from API
     */
    private getEcifIoConsumption() {
        const wbsId = this.projectId ? this.projectId : this.engagementId;
        let tempData: IEcifIoConsumption[];
        tempData = [];
        this.projectService.getEcifIoConsumption(wbsId).then((response) => {
            if (response && response.length) {
                if (this.isProjectContext) {
                    response.forEach((wbs) => {
                        if (wbs.ioDetails && wbs.ioDetails.length) {
                            wbs.ioDetails.forEach((io) => {
                                tempData = this.prepareEcifConsumptionViewModel(wbs.wbsId, io, this.projectData, tempData);
                            });
                        }
                    });
                }
                else {
                    response.forEach((wbs) => {
                        if (wbs.ioDetails && wbs.ioDetails.length) {                            
                            const projectData = this.projectService.getProjectFromWbsId(wbs.wbsId, this.engagementDetails);
                            if (projectData) {
                                wbs.ioDetails.forEach((io) => {
                                    tempData = this.prepareEcifConsumptionViewModel(wbs.wbsId, io, projectData, tempData);
                                });
                            }
                        }
                    });
                }
                if (tempData && tempData.length) {
                    this.ecifConsumption = tempData;
                    this.showECIFConsumption = true;
                    this.isECIFLoading = false;
                    this.calculateEcifConsumptionPercentage();
                }
            }
        });
    }
    
    /**
     * Prepares ECIFIO View Model
     * @param ioWbsId ioWbsId
     * @param ioDetail ioDetail
     * @param project project
     * @param tempData tempData
     * @returns {IEcifIoConsumption[]}
     */
    private prepareEcifConsumptionViewModel(ioWbsId: string, ioDetail: IIoDetailApi, project: IProjectDetailsV2, tempData: IEcifIoConsumption[]): IEcifIoConsumption[] {
        if (!tempData || !tempData.length || !tempData.filter((p) => p.projectId === project.id).length) {
            tempData.push({
                projectId: project.id,
                projectName: project.name,
                isExpanded: true,
                ioDetails: [{
                    ioNumber: ioDetail.ioNumber,
                    wbsId: ioWbsId,
                    startDate: new Date(),
                    endDate: ioDetail.endDate,
                    daysBeforeExpiry: ioDetail.daysBeforeExpiry,
                    consumed: ioDetail.consumedFunds,
                    currency: ioDetail.currency,
                    total: ioDetail.totalFunds,
                    settlementPosting: ioDetail.settlementPosting,
                    previewPosting: ioDetail.previewPosting,
                    userStatusDescription: ioDetail.userStatusDescription,
                    forecastedAmount: ioDetail.forecastedAmount,
                }]
            });
        }
        else {
            if (tempData.filter((p) => p.projectId === project.id)[0].ioDetails.filter((i) => i.ioNumber === ioDetail.ioNumber).length === 0) {
                tempData.filter((p) => p.projectId === project.id)[0].ioDetails.push({
                    ioNumber: ioDetail.ioNumber,
                    wbsId: ioWbsId,
                    startDate: new Date(),
                    endDate: ioDetail.endDate,
                    daysBeforeExpiry: ioDetail.daysBeforeExpiry,
                    consumed: ioDetail.consumedFunds,
                    currency: ioDetail.currency,
                    total: ioDetail.totalFunds,
                    settlementPosting: ioDetail.settlementPosting,
                    previewPosting: ioDetail.previewPosting,
                    userStatusDescription: ioDetail.userStatusDescription,
                    forecastedAmount: ioDetail.forecastedAmount,
                });
            }
        }

        return tempData;
    }

    private calculateEcifConsumptionPercentage() {
        this.ecifConsumedPercentage = 0;
        let total = 0;
        let consumed = 0;
        if (this.ecifConsumption && this.ecifConsumption.length) {
            this.ecifConsumption.forEach((w) => {
                if (w.ioDetails && w.ioDetails.length) {
                    w.ioDetails.forEach((io) => {
                        total += io.total;
                        consumed += io.consumed;
                    });
                }
            });
        }
        if (total !== 0 && consumed !== 0) {
            this.ecifConsumedPercentage = ( consumed / total ) * 100;
        }
    }

    /**
     * Load & set the data  for the component
     */
    private populateData(callClinSlin: boolean): void {
        if (this.riskReserveDetails && this.riskReserveDetails[0] && this.riskReserveDetails[0].bidCostAmount && this.approvedFinancialData && this.approvedFinancialData.length) {
            let consumedRrAmount = 0;
            for (const projectReserve of this.approvedFinancialData) {
                consumedRrAmount += projectReserve.consumedRiskReserve;
            }

            this.riskReserveConsumedPercentage = (consumedRrAmount / this.riskReserveDetails[0].bidCostAmount) * 100;
        } else {
            this.riskReserveConsumedPercentage = 0;
        }
        if (!this.isProjectContext) {
            this.capAmountService.getCapAmountDetails(this.engagementId).then((response) => {
                this.capAmountDetails = response[0];
                if (this.capAmountDetails) {
                    this.capAmtExceedPercentage = this.configurationService.getValue<number>("CapAmtConsumedPercentageLimit");
                    if (this.capAmountDetails.laborCap !== undefined && this.capAmountDetails.laborCap > 0) {
                        const totalCapAmountConsumed = this.capAmountDetails.unbilledLaborCap + this.capAmountDetails.billedLaborCap;
                        if (totalCapAmountConsumed) {
                            this.tmCapConsumedPercentage = (totalCapAmountConsumed / this.capAmountDetails.laborCap) * 100;
                        }
                    } else if (this.capAmountDetails.expenseCap !== undefined && this.capAmountDetails.expenseCap > 0) {
                        const totalExpenseAmountConsumed = this.capAmountDetails.unbilledExpenseCap + this.capAmountDetails.billedExpenseCap;
                        if (totalExpenseAmountConsumed) {
                            this.tmCapConsumedPercentage = (totalExpenseAmountConsumed / this.capAmountDetails.expenseCap) * 100;
                        }
                    } else if (this.capAmountDetails.totalContractCap !== undefined && this.capAmountDetails.totalContractCap > 0) {
                        const totalContractCapAmountConsumed = this.capAmountDetails.totalUnbilled + this.capAmountDetails.totalBilledContractCap;
                        if (totalContractCapAmountConsumed) {
                            this.tmCapConsumedPercentage = (totalContractCapAmountConsumed / this.capAmountDetails.totalContractCap) * 100;
                            if (this.tmCapConsumedPercentage > this.capAmtExceedPercentage) {
                                this.isCapAmtExceeds = true;
                            }
                        }
                    }

                    if (this.tmCapConsumedPercentage && this.tmCapConsumedPercentage > this.capAmtExceedPercentage) {
                        this.isCapAmtExceeds = true;
                    }

                }
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.PopulateData, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
        }

        /**
         * For clin slin modal popup
         */
        this.showClinSlin = false;
        if (callClinSlin) {
            this.showClinSlin = true;
            this.loadClinSlin();
        }

        this.isUnapprovedSectionEnabled();
        this.getUnapprovedExpenseFromConcur();
    }

    /**
     * Calculate unapproved total expense. Build expense data with group by project to bind in modal popup
     *
     * @memberof KeyIndicatorsComponent
     */
    private getUnapprovedExpenses(): void {
        for (const expense of this.laborExpenseListAPI) {
            if (expense.entries) {
                for (const entry of expense.entries) {
                    if (this.isProjectContext) {
                        if (entry.projectId === this.projectId) {
                            this.buildExpenseDetailsList(expense, entry);
                        }
                    } else {
                        if (entry.engagementId === this.engagementId) {
                            this.buildExpenseDetailsList(expense, entry);
                        }
                    }
                }
            }
        }

        const source = from(this.expenseDetailList);
        // group by projectID
        const groupbyProjectID = source.pipe(
            groupBy((expense) => expense.projectId),
            // return each item in group as array
            mergeMap((group) => group.pipe(toArray()))
        );
        this.expenseEntryListGroupbyProjectID = [];
        groupbyProjectID.subscribe((val) => /* subscribe group project data */
            this.expenseEntryListGroupbyProjectID.push(val)
        );

        for (const project of this.expenseEntryListGroupbyProjectID) {
            const entries: IEntry[] = [];
            project.forEach((expense: IEntry) => {
                entries.push({
                    submitterName: expense.submitterName,
                    submittedDate: expense.submittedDate,
                    projectAmount: expense.projectAmount,
                    currencyCode: expense.currencyCode,
                    reportTitle: expense.reportTitle,
                    category: expense.category,
                    subCategory: expense.subCategory,
                    isSubcategoryExpanded: true
                });
            });
            this.unapprovedExpenseEntries.push({
                projectId: project[0].projectId,
                projectName: project[0].projectName,
                isProjectExpanded: true,
                entries
            });
        }
        this.isExpenseDetailsLoading = false;
    }

    /**
     * Build Expense detials list and calculate unapproved expense total amount
     *
     * @param {*} expense
     * @param {*} entry
     * @memberof KeyIndicatorsComponent
     */
    private buildExpenseDetailsList(expense: any, entry: any): void { // todo replace any with correct types when working with API result
        this.expenseDetailList.push({
            submitterName: `${expense.submitter.firstName} ${expense.submitter.lastName}`,
            projectId: entry.projectId,
            projectAmount: entry.projectAmount.amount,
            currencyCode: entry.projectAmount.currency,
            submittedDate: entry.transactionDate,
            projectName: this.engagementDetails.projects.filter((project) => project.id === entry.projectId).length ? this.engagementDetails.projects.filter((project) => project.id === entry.projectId)[0].name : "",
            reportTitle: expense.name,
            category: entry.catetory,
            subCategory: entry.subCategory
        });
        this.unapprovedExpense = this.unapprovedExpense + entry.projectAmount.amount;
    }

    /**
     * Get Unapproved expense from concur API and build expense data
     * 
     * @private
     * @memberof KeyIndicatorsComponent
     * @param loadFromCache
     */
    private getUnapprovedExpenseFromConcur(): void {
        if (this.isUnapprovedExpenseEnabled) {
            this.isExpenseDetailsLoading = true;
            const queryString = "employeeId=" + this.fxpUserInfoService.getCurrentUserData().personnelNumber.toString();
            const key = `${CacheKeys.ConcurPendingReportsApproval.KeyName}-${queryString}`;
            this.cacheService.get(key, () => this.concurService.getPendingReportsforApproval(queryString), CacheKeys.ConcurPendingReportsApproval.Duration)
                .then((response: IConcurPendingReportsResponse[]) => {
                    this.laborExpenseListAPI = response;
                    // todo check if response.entries exists?
                    this.getUnapprovedExpenses();
                }).catch((error) => {
                    this.isExpenseDetailsLoading = false;
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.logError(SourceConstants.Method.GetUnapprovedExpenseFromConcur, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                });
        }
    }

    /**
     * Determine if the Engagement\Project is released to decide on the Unapproved section is enabled
     *
     * @private
     * @memberof KeyIndicatorsComponent
     */
    private isUnapprovedSectionEnabled(): void {
        if (this.isProjectContext && this.projectData.statusCode !== WBS_STATUSCODE_UNAPPROVED_COST) {
            this.isUnapprovedExpenseEnabled = true;
            this.isUnapprovedLaborEnabled = true;
        } else if (this.engagementDetails.statusCode !== WBS_STATUSCODE_UNAPPROVED_COST) {
            this.isUnapprovedExpenseEnabled = true;
            this.isUnapprovedLaborEnabled = true;
        }
    }

    /**
     * Data fetching for Clinslin modal using API
     */
    private loadClinSlin(): void {
        this.clinSlinApiResponse = null;
        this.clinSlinConsumedPercentage = 0;
        this.consumedAmount = 0;
        this.fundedAmount = 0;
        this.isClinSlinLoading = true;
        this.clinSlinDisplayObject = [];
        this.nonClinSlinDemandModel = [];
        this.clinSlinDemands = [];
        if (this.isProjectContext) {
            this.projectService.getClinSlinForProjectId(this.projectId)
                .then((response: IClinSlinApiResponse) => {
                    this.clinSlinApiResponse = response;
                    this.clinSlinDisplayObject = [];
                    this.populateClinSlinDisplayModel();
                    this.isClinSlinLoading = false;
                }).catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.logError(SourceConstants.Method.LoadClinSlin, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.populateNonClinslinDemands();
                    this.isClinSlinLoading = false;
                });
        } else {
            this.projectService.getClinSlinForEngagementId(this.engagementDetails.id)
                .then((response: IClinSlinApiResponse) => {
                    const unitBasedProjects = this.engagementDetails.projects.filter((proj) => proj.hasUnitBasedDemands);
                    if (unitBasedProjects && unitBasedProjects.length > 0) {
                        const unitBasedProjectsIds = unitBasedProjects.map((proj) => proj.id.substring(0, 16));
                        response.fundingDetails = response.fundingDetails.filter((entity) => unitBasedProjectsIds.indexOf(entity.wbsId.substring(0, 16)) === -1);
                    }
                    this.clinSlinApiResponse = response;
                    this.clinSlinDisplayObject = [];
                    this.populateClinSlinDisplayModel();
                    this.isClinSlinLoading = false;
                }).catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.logError(SourceConstants.Method.LoadClinSlin, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.populateNonClinslinDemands();
                    this.isClinSlinLoading = false;
                });
        }
    }

    /**
     * Build the model for displaying ClinSlin based on the ClinslinId/BillingRole/Project/Demand model       
     */
    private populateClinSlinDisplayModel(): void {
        this.clinSlinApiResponse.fundingDetails.map((fundingDetail) => fundingDetail.clinSlinFundingId = fundingDetail.clinSlinId + "/" + fundingDetail.fundingId);
        let uniqueClinSlinIds = this.clinSlinApiResponse.fundingDetails.map((fundingdetail) => fundingdetail.clinSlinFundingId);
        uniqueClinSlinIds = uniqueClinSlinIds.filter((x, i, a) => a.indexOf(x) === i);
        for (const clinSlinId of uniqueClinSlinIds) {
            this.clinSlinDisplayObject.push({
                clinSlinFundingId: clinSlinId,
                clinSlinRoleDetails: this.getClinSlinRoleDetails(this.clinSlinApiResponse, clinSlinId),
                plannedHours: 0,
                fundedHours: 0,
                fundedAmount: 0,
                consumedAmount: 0,
                isTasksExpanded: true
            });
        }
        this.calculateFundedHours(this.clinSlinDisplayObject);
        this.populateNonClinslinDemands();
    }

    /**
     * Build the model for displaying demands which are not associated with clinslin       
     */
    private populateNonClinslinDemands(): void {
        const projectDetailsArray: IProjectDetailsV2[] = this.isProjectContext ? [this.projectData] : this.engagementDetails.projects;
        for (const proj of projectDetailsArray) {
            const nonClinSlinDemandModel: IClinSlinProjectDetails = {
                clinSlinId: undefined,
                resourceItemId: undefined,
                plannedHours: 0,
                projectName: proj.name,
                projectId: proj.id,
                associatedDemandDetails: this.getNonClinSlinDemandModel(proj.id)
            };
            if (nonClinSlinDemandModel.associatedDemandDetails.length > 0) {
                nonClinSlinDemandModel.plannedHours = nonClinSlinDemandModel.associatedDemandDetails.map((detail) => detail.plannedHours).reduce((prevvalue, currvalue) => prevvalue + currvalue);
            }
            this.nonClinSlinDemandModel.push(nonClinSlinDemandModel);
        }
    }

    /**
     * Get the nonclinslin demand details model
     * @param projectId      
     */
    private getNonClinSlinDemandModel(projectId: string): IClinSlinDemandDetails[] {
        const demandDetails: IClinSlinDemandDetails[] = [];
        this.clinSlinDemands.pop();
        if (this.demandsDetails && this.demandsDetails.length) {
            for (const demand of this.demandsDetails) {
                if (demand.planned && this.checkIfDemandBelongsToProject(projectId, demand.planned.structureId) && (this.clinSlinDemands.indexOf(demand.demandId) === -1)) {
                    demandDetails.push({
                        clinSlinId: undefined,
                        resourceItemId: undefined,
                        plannedHours: demand.planned.plannedHours,
                        consumedHours: undefined,
                        consumedAmount: undefined,
                        unBilledAmount: undefined,
                        unBilledHours: undefined,
                        billedHours: undefined,
                        billedAmount: undefined,
                        demandId: demand.demandId,
                        demandRoleName: demand.planned.roleDescription
                    });
                }
            }
        }
        return demandDetails;
    }

    /**
     * Calculate Planned hours, funded hours, fundedamount at various levels in the model
     * @param clinSlinDisplayObject      
     */
    private calculateFundedHours(clinSlinDisplayObjectArray: IClinSlinDisplayObject[]) {
        for (const clinSlinObject of clinSlinDisplayObjectArray) {
            if (clinSlinObject.clinSlinRoleDetails.length > 0) {
                for (const roleDetail of clinSlinObject.clinSlinRoleDetails) {
                    for (const projectDetail of roleDetail.associatedProjectDetails) {
                        projectDetail.plannedHours = projectDetail.associatedDemandDetails.length > 0 ? projectDetail.associatedDemandDetails.map((demand) => demand.plannedHours).reduce((prevValue, currentValue) => prevValue + currentValue) : 0;
                    }
                    roleDetail.plannedHours = roleDetail.associatedProjectDetails.length > 0 ? roleDetail.associatedProjectDetails.map((projDetail) => projDetail.plannedHours).reduce((prevValue, currentValue) => prevValue + currentValue) : 0;
                }
                clinSlinObject.plannedHours = clinSlinObject.clinSlinRoleDetails.map((roleDetail) => roleDetail.plannedHours).reduce((prevValue, currentValue) => prevValue + currentValue);
                clinSlinObject.fundedAmount = clinSlinObject.clinSlinRoleDetails.map((roleDetail) => roleDetail.fundedAmount).reduce((prevValue, currentValue) => prevValue + currentValue);
                clinSlinObject.fundedHours = clinSlinObject.clinSlinRoleDetails.map((roleDetail) => roleDetail.fundedHours).reduce((prevValue, currentValue) => prevValue + currentValue);
                clinSlinObject.consumedAmount = clinSlinObject.clinSlinRoleDetails.map((roleDetail) => roleDetail.consumedAmount).reduce((prevValue, currentValue) => prevValue + currentValue);
            }
        }
        this.consumedAmount = clinSlinDisplayObjectArray.map((entity) => entity.consumedAmount).reduce((prevValue, currentValue) => prevValue + currentValue);
        this.fundedAmount = clinSlinDisplayObjectArray.map((entity) => entity.fundedAmount).reduce((prevValue, currentValue) => prevValue + currentValue);
        if (this.fundedAmount) {
            this.clinSlinConsumedPercentage = Number((this.consumedAmount / this.fundedAmount * 100).toFixed(2));
        }
    }

    /**
     * Get the role details for each clinslinid
     * @param clinSlinApiResponse 
     * @param clinSlinId 
     */
    private getClinSlinRoleDetails(clinSlinApiResponse: IClinSlinApiResponse, clinSlinId: string): IClinSlinRoleDetail[] {
        const clinslinRoleDetails: IClinSlinRoleDetail[] = [];
        const filteredApiResponse = clinSlinApiResponse.fundingDetails.filter((details) => details.clinSlinFundingId === clinSlinId);
        for (const fundingdetail of filteredApiResponse) {
            clinslinRoleDetails.push({
                roleName: this.getRoleName(fundingdetail.rolePartNumber),
                rolePartNumber: fundingdetail.rolePartNumber,
                resourceItemId: fundingdetail.resourceItemId,
                billRate: Number(fundingdetail.billRate),
                fundedHours: fundingdetail.fundedQuantity,
                fundedAmount: Number(fundingdetail.fundedAmount),
                plannedHours: 0,
                consumedHours: fundingdetail.billedQuantity + fundingdetail.unBilledQuantity,
                consumedAmount: Number(fundingdetail.unBilledAmount) + Number(fundingdetail.billedAmount),
                percentageConsumed: fundingdetail.fundedAmount && Number(fundingdetail.fundedAmount) ? (((Number(fundingdetail.unBilledAmount) + Number(fundingdetail.billedAmount)) / Number(fundingdetail.fundedAmount)) * 100).toFixed(2).toString() : "0.00",
                associatedProjectDetails: this.getProjectDetailsAssociatedWithClinslin(fundingdetail)
            });
        }
        return clinslinRoleDetails;
    }

    /**
     * Get the role name based on the role part number
     * if the financial roles are available get the role name from the api response else return the role part number instead of returning blank
     * @param rolePartNumber     
     */
    private getRoleName(rolePartNumber: string): string {
        if (this.projectServiceFunctions.rolesValues && this.projectServiceFunctions.rolesValues.length > 0) {
            const filteredRoles = this.projectServiceFunctions.rolesValues.filter((roleDetail) => roleDetail.rolePartNumber === rolePartNumber);
            if (filteredRoles.length > 0) {
                return filteredRoles[0].roleName;
            }
        }
        return rolePartNumber;
    }


    /**
     * Get the Project details for each clinslinid based on the resource item id
     * @param fundingdetail     
     */
    private getProjectDetailsAssociatedWithClinslin(fundingdetail: IFundingDetail): IClinSlinProjectDetails[] {
        const clinsProjectDetails: IClinSlinProjectDetails[] = [];
        if (this.demandsDetails && this.demandsDetails.length) {
            const filtereddemandDetails = this.demandsDetails.filter((demand) => demand.planned && demand.planned.resourceItemId === fundingdetail.resourceItemId && demand.planned.structureId.substring(0, 16) === fundingdetail.wbsId.substring(0, 16));
            if (filtereddemandDetails.length === 0) {
                const projectDetails: IProjectDetailsV2 = this.getProjectDetailsBasedOnTaskId(fundingdetail.wbsId);
                const clinslinprojDetail = {
                    clinSlinId: fundingdetail.clinSlinFundingId,
                    resourceItemId: fundingdetail.resourceItemId,
                    projectName: projectDetails.name,
                    projectId: projectDetails.id,
                    associatedDemandDetails: []
                };
                clinsProjectDetails.push(clinslinprojDetail);
            }
            for (const demand of filtereddemandDetails) {
                if (this.clinSlinDemands.filter((demandId) => demandId === demand.demandId).length === 0) {
                    this.clinSlinDemands.push(demand.demandId);
                }
                const projectDetails: IProjectDetailsV2 = this.getProjectDetailsBasedOnTaskId(demand.planned.structureId);
                if (clinsProjectDetails.filter((details) => details.projectId === projectDetails.id).length === 0) {
                    const clinslinprojDetail = {
                        clinSlinId: fundingdetail.clinSlinFundingId,
                        resourceItemId: fundingdetail.resourceItemId,
                        projectName: projectDetails.name,
                        projectId: projectDetails.id,
                        associatedDemandDetails: []
                    };
                    clinslinprojDetail.associatedDemandDetails.push(this.getDemandDetailsAssociatedWithClinslin(fundingdetail, demand));
                    clinsProjectDetails.push(clinslinprojDetail);
                } else {
                    const clinslinprojDetail = clinsProjectDetails.filter((details) => details.projectId === projectDetails.id)[0];
                    clinslinprojDetail.associatedDemandDetails.push(this.getDemandDetailsAssociatedWithClinslin(fundingdetail, demand));
                }
            }
        }
        return clinsProjectDetails;
    }


    /**
     * Get the Demand details Models for each the project associated
     * @param fundingdetail     
     */
    private getDemandDetailsAssociatedWithClinslin(fundingdetail: IFundingDetail, demand: IDemandDetails): IClinSlinDemandDetails {
        const clinsDemandDetails: IClinSlinDemandDetails = {
            clinSlinId: fundingdetail.clinSlinFundingId,
            resourceItemId: fundingdetail.resourceItemId,
            plannedHours: demand.planned.plannedHours,
            consumedHours: fundingdetail.billedQuantity + fundingdetail.unBilledQuantity,
            consumedAmount: Number(fundingdetail.unBilledAmount) + Number(fundingdetail.billedAmount),
            unBilledAmount: Number(fundingdetail.unBilledAmount),
            unBilledHours: fundingdetail.unBilledQuantity,
            billedHours: fundingdetail.billedQuantity,
            billedAmount: Number(fundingdetail.billedAmount),
            demandId: demand.demandId,
            demandRoleName: demand.planned.roleDescription
        };
        return clinsDemandDetails;
    }


    /**
     * Retrieve project details based on Structure Id or wbsL3 id
     * @param structureId     
     */
    private getProjectDetailsBasedOnTaskId(structureId: string): IProjectDetailsV2 {
        if (this.isProjectContext) {
            return this.projectData;
        } else {
            return this.engagementDetails.projects.filter((project) => project.id.substring(0, 16) === structureId.substring(0, 16))[0];
        }
    }


    /**
     * Find if demand belongs to a project
     * @param structureId 
     * @param projectId 
     */
    private checkIfDemandBelongsToProject(projectId: string, structureId: string): boolean {
        return (projectId.substring(0, 16) === structureId.substring(0, 16));
    }

    /**
     * Get LaborData grouped by weeks from APIResponse
     * @param filteredApiResponse 
     */
    private getLaborDataWeekly(filteredApiResponse: ILaborDetail[]): ILaborWeeklyData[] {
        const laborWeeklyData: ILaborWeeklyData[] = [];
        filteredApiResponse.map((laborentryDetails) => {
            laborentryDetails.laborWeekNumber = moment(laborentryDetails.laborDate).isoWeek();
        });
        filteredApiResponse.forEach((laborentryDetails) => {
            const yearOfLabor = moment(laborentryDetails.laborDate).year();
            if (!laborentryDetails.isAdded) {
                laborWeeklyData.push({
                    weekNumber: laborentryDetails.laborWeekNumber,
                    weekStartDate: moment().year(yearOfLabor).isoWeek(laborentryDetails.laborWeekNumber).startOf("isoWeek").format("DD-MMM"),
                    weekEndDate: moment().year(yearOfLabor).isoWeek(laborentryDetails.laborWeekNumber).endOf("isoWeek").format("DD-MMM"),
                    laborDetails: this.getLaborDetails(filteredApiResponse, laborentryDetails.laborWeekNumber)
                });
            }
        });
        return laborWeeklyData;
    }

    /**
     * Get Labordetails adding resource alias to the data grouped by weeks from APIResponse
     * @param filteredApiResponse 
     */
    private getLaborDetails(filteredApiResponse: ILaborDetail[], weekNumber: number): ILaborDetails[] {
        const laborDetails: ILaborDetails[] = [];
        const filteredLaborEntryListBasedOnWeekNumber = filteredApiResponse.filter((obj) => obj.laborWeekNumber === weekNumber);
        filteredLaborEntryListBasedOnWeekNumber.forEach((laborentryDetails) => {
            laborDetails.push({
                laborHours: moment.duration(moment.duration(laborentryDetails.laborHours).asSeconds() * 1000).asHours(),
                resourceAlias: laborentryDetails.submittedFor
            });
            this.unApprovedLaborResources.push(laborentryDetails.submittedFor);
            laborentryDetails.isAdded = true;
        });
        return laborDetails;
    }

    /**
     * Calculate percentage with respect to the eac details cost
     * @param eacCost
     * @param cost 
     */
    private getCostPercentage(eacCost: number, cost: number): number {
        if (cost && eacCost) {
            return ((eacCost - cost) / eacCost) * 100;
        }
        return 0;
    }

    /**
     * Manages the loading state and errors of the given loadable state items.
     *
     * @private
     * @param {...ILoadableState[]} items
     * @memberof FinancialPlanComponent
     */
    private checkLoadingStatus(...items: ILoadableState[]): void {
        this.refreshOnItemInvalidation(...items);
        this.setLoadersBasedOnItemState(...items);
        this.setErrorsBasedOnItemState(...items);
    }

    /**
     * Gets the assignments from GRM Response 
     * @param grmRequestList     
     */
    private getAssignmentsFromGRMResponse(grmRequestList: IResourceRequestResponse, projectId?: string): IAssignmentDetails[] {
        const assignmentDetails: IAssignmentDetails[] = [];
        if (grmRequestList.ProjectRequests && grmRequestList.ProjectRequests.length > 0) {
            for (const projectRequest of grmRequestList.ProjectRequests) {
                if (projectRequest.ResourceRequests && projectRequest.ResourceRequests.length > 0) {
                    for (const resourceRequest of projectRequest.ResourceRequests) {
                        if (pendingApprovalStatus.indexOf(resourceRequest.ResourceRequestStatusEnum) > -1 && !projectId) {
                            assignmentDetails.push({
                                resourceId: resourceRequest.ResourceRequestId.toString(),
                                resourceAlias: resourceRequest.AssignedResource
                            });
                        } else if (projectId && projectRequest.DemandSourceId && projectRequest.DemandSourceId.substring(0, 19) === projectId && pendingApprovalStatus.indexOf(resourceRequest.ResourceRequestStatusEnum) > -1) {
                            assignmentDetails.push({
                                resourceId: resourceRequest.ResourceRequestId.toString(),
                                resourceAlias: resourceRequest.AssignedResource
                            });
                        }
                    }
                }
            }
        }
        return assignmentDetails;
    }

    /**
     * Gets pending labor details for a given project for a given project
     *
     * @private
     * @param {(IProjectDetails | IProjectDetailsV2)} projectDetails
     * @returns
     * @memberof KeyIndicatorsComponent
     */
    private getProjectsPendingLabor(projectDetails: IProjectDetails | IProjectDetailsV2): Promise<void> {
        const assignmentIds: IAssignmentDetails[] = this.getAssignmentsFromGRMResponse(this.resourceRequestDetails, (projectDetails as IProjectDetailsV2).id);
        this.projectLaborResponses = [];

        return Promise.all(assignmentIds.map((assignment: IAssignmentDetails) => {
            return this.laborManagementService.getBulkLaborEntries(assignment.resourceId, assignment.resourceAlias).catch((err) => {
                // If 404 error is caught, resolve promise as it just means there is no unapproved labor for given assignment ID
                if (err.status === 404) {
                    this.logError(SourceConstants.Method.GetProjectsPendingLabor, err, DmError.KeyIndicator.NoUnapprovedlabor);
                    Promise.resolve();
                } else {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(err, "");
                    this.logError(SourceConstants.Method.GetProjectsPendingLabor, err, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                }
            });
        })).then((result: IBulkLaborEntries[]) => {

            result.forEach((laborDetails: IBulkLaborEntries) => {
                if (laborDetails && laborDetails.response && laborDetails.response.laborResponse) {
                    const filteredApiResponse: ILaborDetail[] = laborDetails.response.laborResponse.filter((labor: ILaborDetail) => labor.laborStatus.toLowerCase() === "submitted");
                    if (!filteredApiResponse.length) {
                        return undefined;
                    }
                    filteredApiResponse.forEach((laborEntry: ILaborDetail) => {
                        laborEntry.isAdded = false;
                    });
                    this.projectLaborResponses.push(...filteredApiResponse);
                }
            });
        }).catch((error) => {
            // If 404 error is caught, resolve promise as it just means there is no unapproved labor for given assignment ID
            if (error.status === 404) {
                this.logError(SourceConstants.Method.GetProjectsPendingLabor, error, DmError.KeyIndicator.NoUnapprovedlabor);
                Promise.resolve();
            }
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.GetProjectsPendingLabor, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
        });
    }

    private prepareUnapprovedLaborViewList(project: IProjectDetailsV2 | IProjectDetails, unapprovedLabor: ILaborDetail[]) {
        const unApprovedLaborViewModel: IUnapprovedLaborList = {
            projectId: (project as IProjectDetailsV2).id,
            projectName: (project as IProjectDetailsV2).name,
            laborWeeklyData: this.getLaborDataWeekly(unapprovedLabor)
        };

        if (unApprovedLaborViewModel.laborWeeklyData && unApprovedLaborViewModel.laborWeeklyData.length) {
            for (const laborData of unApprovedLaborViewModel.laborWeeklyData) {
                laborData.totalHours = laborData.laborDetails.reduce((hours, details) => hours + details.laborHours, 0);
            }
            unApprovedLaborViewModel.totalHours = unApprovedLaborViewModel.laborWeeklyData.reduce((hours, details) => hours + details.totalHours, 0);
        }
        this.unapprovedLaborList.push(unApprovedLaborViewModel);
    }

}
