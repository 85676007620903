import { forwardRef, Inject, Injectable } from "@angular/core";
import { FxpHttpService } from "@fxp/fxpservices";

import { APIConstants, Services } from "../../common/application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { IFinancialRoles, IFinancialRolesAPIResponse, IUserMembership, ICheckUserMembershipResponse, ISendRequestEmail } from "./contracts/projectservice-functions.contract";
import { IWbsEditEngagementDetailsV2, IWbsOrchestrationRequest, WbsLevel } from "./contracts/wbs.service.contracts";
import { IGrmProjectCreateFunctionRequest } from "../../components/new-internal-engagement/internal-engagement-grm.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";
import { IRiskReserveOrchestrationRequest, IRiskReserveRequest } from "./contracts/changerequest.contract";

const excludedRolePartNumbers: string[] = [
    "AAA-86595", // billable materials,
    "AAA-66325", // non billable materials
    "AAA-86608", // Premier Field Engineer - CL
];

@Injectable()
export class ProjectServiceFunctions extends DmServiceAbstract {

    public rolesValues: IFinancialRoles[] = [];
    private projectServiceFunctionUri: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(forwardRef(() => FxpHttpService)) protected fxpHttpService: FxpHttpService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.ProjectServiceFunction);
    }

    /**
     * Get unit roles
     */
    public getUnitRoles(): Promise<IFinancialRoles[]> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        this.projectServiceFunctionUri = this.projectServiceFunctionUri + "/v3.0/unitRoles";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        return this.dataService.getData(this.projectServiceFunctionUri, this.subscriptionKey, APIConstants.unitRolesAPI, undefined, true, false, true)
            .then((response: IFinancialRolesAPIResponse[]) => {
                this.rolesValues = [];
                response = response.filter((roleObject: IFinancialRolesAPIResponse) => {
                    return roleObject
                        && roleObject.ActivityType
                        && roleObject.ActivityType.Value
                        && roleObject.ActivityType.Value !== ""
                        && (excludedRolePartNumbers.indexOf(roleObject.PartNumber) === -1);
                });
                response.forEach((data: IFinancialRolesAPIResponse) => {
                    const financialRole: IFinancialRoles = {
                        rolePartNumber: data.PartNumber,
                        roleName: data.ActivityType.Value,
                        activityCode: data.ActivityType.Code
                    };
                    this.rolesValues.push(financialRole);
                });
                return this.rolesValues;
            });

    }

    /**
     * Get financial roles
     */
    public getFinancialRoles(): Promise<IFinancialRoles[]> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        this.projectServiceFunctionUri = this.projectServiceFunctionUri + "/v3.0/financialroles";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        return this.dataService.getData(this.projectServiceFunctionUri, this.subscriptionKey, APIConstants.FinancialRolesAPI, undefined, true, false, true)
            .then((response: IFinancialRolesAPIResponse[]) => {
                this.rolesValues = [];
                response = response.filter((roleObject: IFinancialRolesAPIResponse) => {
                    return roleObject
                        && roleObject.Name
                        && (excludedRolePartNumbers.indexOf(roleObject.PartNumber) === -1);
                });
                response.forEach((data: IFinancialRolesAPIResponse) => {
                    const financialRole: IFinancialRoles = {
                        rolePartNumber: data.PartNumber,
                        roleName: data.Name,
                        isCleared: data.RequiredClearance && (data.RequiredClearance.Value === "Cleared"),
                        isFte: data.ResourceType.Id === 1,
                        activityCode: data.ActivityType.Code
                    };
                    this.rolesValues.push(financialRole);
                });
                return this.rolesValues;
            });

    }

    /**
     * Trigger Engagement Date Change process by calling the orchestrate function.
     *
     * @param {IWbsEditEngagementDetailsV2} dateChangeRequest
     * @param {string} engagementId
     * @param {string} engagementName
     * @param {string} orchestrationId
     * @returns {Promise<string>}
     * @memberof ProjectServiceFunctions
     */
    public orchestrateDateChange(dateChangeRequest: IWbsEditEngagementDetailsV2, wbsId: string, wbsName: string, orchestrationId: string, level: WbsLevel, requestorAlias: string): Promise<string> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        this.projectServiceFunctionUri = this.projectServiceFunctionUri + "/v3.0/orchestrate/datechange";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        const orchestrationRequest: IWbsOrchestrationRequest = {
            RequestedBy: requestorAlias,
            WbsId: wbsId,
            WbsName: dateChangeRequest.name ? dateChangeRequest.name : wbsName,
            OrchestrationId: orchestrationId,
            Description: dateChangeRequest.description,
            StartDate: dateChangeRequest.startDate,
            EndDate: dateChangeRequest.endDate,
            Level: level,
            ShouldCascade: dateChangeRequest.shouldCascadeUpdate,
            TeamStructure: dateChangeRequest.teamStructure
        };

        return this.dataService.postData(this.projectServiceFunctionUri, this.subscriptionKey, APIConstants.OrchestrateDateChange, orchestrationRequest);
    }

    /**
     * Trigger Release and Activate Process by calling the orchestrate function.
     *
     * @param {string} userAlias
     * @param {string} userBpid
     * @param {string} engagementId
     * @param {string} engagementName
     * @param {string} orchestrationId
     * @returns {Promise<string>}
     * @memberof ProjectServiceFunctions
     */
    public orchestrateReleaseAndActivate(userAlias: string, userBpid: string, engagementId: string, engagementName: string, orchestrationId: string): Promise<string> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        this.projectServiceFunctionUri = this.projectServiceFunctionUri + "/v3.0/orchestrate/releaseandactivate";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        const orchestrationRequest: IWbsOrchestrationRequest = {
            WbsId: engagementId,
            WbsName: engagementName,
            OrchestrationId: orchestrationId
        };

        return this.dataService.postData(this.projectServiceFunctionUri, this.subscriptionKey, APIConstants.OrchestrateReleaseandActivate, orchestrationRequest);
    }

    /**
     * Trigger Risk reserve change orchestrate function
     *
     * @param {string} userAlias
     * @param {string} userBpid
     * @param {string} engagementId
     * @param {string} orchestrationId
     * @param {IRiskReserveRequest} riskReserveRequest
     * @return {*}  {Promise<string>}
     * @memberof ProjectServiceFunctions
     */
    public orchestrateRiskReserveUpdate(userAlias: string, engagementId: string, orchestrationId: string, riskReserveRequest: IRiskReserveRequest): Promise<string> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        this.projectServiceFunctionUri = this.projectServiceFunctionUri + "/v3.0/orchestrate/riskreserve";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        const orchestrationRequest: IRiskReserveOrchestrationRequest = {
            WbsId: engagementId,
            RequestedBy: userAlias,
            OrchestrationId: orchestrationId,
            UpdateTaskRRDetailsRequest: riskReserveRequest
        };

        return this.dataService.postData(this.projectServiceFunctionUri, this.subscriptionKey, APIConstants.OrchestrateRiskReserveChange, orchestrationRequest);
    }

    /**
     * Save Financial Plan Status
     * @param engagementOrProjectId
     * @param savedOnUtcDate
     * @param planName
     * @param savedViews Array of saved views
     */
    public saveFinancialPlanSaveAction(engagementOrProjectId: string, savedOnUtcDate: Date, planVersion: string, savedViews: string[]): Promise<string> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        const url = this.projectServiceFunctionUri + "/v3.0/financialplan/" + engagementOrProjectId;

        return this.dataService.postData(url, "", APIConstants.SaveFinancialPlanUpdate, {
            savedOnUtc: savedOnUtcDate.toISOString(), // "2020-01-05T03:45:46.300Z"
            planVersion,
            savedViews
        });
    }

    /**
     * Returns user belongs to AD groups
     * @param data
     */
    public checkUserGroupMemberShip(data: IUserMembership[]): Promise<ICheckUserMembershipResponse[]> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        const url = this.projectServiceFunctionUri + "/v3.0/checkmembership";
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.CheckUserMembership, data);
    }

    /**
     * Returns whether user belongs to particular org
     * @param userAliasstateTo
     * @returns {Promise<boolean>}
     * @memberof ProjectServiceFunctions
     */
    public checkUserHierarchy(userAlias: string): Promise<boolean> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        const url = this.projectServiceFunctionUri + "/v3.0/checkuserhierarchy/" + userAlias;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.CheckUserHierarchy);
    }

    /**
     * call GRM Create Project Function
     */
    public createInternalProjectToGrm(inputData: IGrmProjectCreateFunctionRequest): Promise<any> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        const url = this.projectServiceFunctionUri + "/v3.0/project/grm/create";
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.CreateProjectInGRM, inputData);
    }

    /**
     * Check if any project closure run is in progress by calling the orchestrate function.
     *
     * @param {string} wbsId
     * @param {string} stateTo
     * @returns {Promise<boolean>}
     * @memberof ProjectServiceFunctions
     */
    public checkProjectClosureOrchestrationRun(wbsId: string, stateTo: string): Promise<boolean> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        const url = `${this.projectServiceFunctionUri}/v3.0/Orchestration/CheckOrchestrationRun/${wbsId}/${stateTo}`;
        
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");

        return this.dataService.getData(url, this.subscriptionKey, APIConstants.InPogressRequestCheck);
    }

    /**
     * Triggers email notification about the 
     */
    public sendEmailNotificationAboutProjects(inputData: ISendRequestEmail): Promise<any> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV3BaseUrl");
        const url = this.projectServiceFunctionUri + "/v3.0/request/grm/emailNotify";
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.CreateProjectInGRM, inputData);
    }
}

